import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import RowHead from '@firsttable/web-components/molecules/RowHead';
import {
  Col,
  Flex,
  Box,
  Title,
  Text,
  Row,
} from '@firsttable/web-components/atoms';
import ContainerContent from '@firsttable/web-components/molecules/ContainerContent';
import imgBird from '../components/organisms/ContactUs/bird.svg';
import ContactForm from '../components/organisms/Forms/DynamicUserForm';
import imgFork from '../components/organisms/ContactUs/fork.svg';
import parseHtml from '../helpers/parseHtml';
import SEO from '../components/seo';
import GenericLayout from '../layouts/GenericLayout';
import { isGlobalSite } from '../helpers/data';
import usePageRehydrate from '../hooks/usePageRehydrate';

const ContactUs = () => {
  if (isGlobalSite) {
    return (
      <SEO meta={[
        { 'http-equiv': 'refresh', content: '0;url=/' },
      ]}
      />
    );
  }
  const { contactPage } = useStaticQuery(
    graphql`
      query getContactForm {
        contactPage: contactForm(slug: {eq: "/contact-us/"}) {
          className
          content
          sidebarContent
          foreignId
          id
          menuTitle
          metaDescription
          metaTitleFormatted
          metaTags
          ogImage
          onCompleteMessage
          slug
          title
          parentId
          formFields {
            edges {
              node {
                id
                name
                required
                sort
                title
                type
                options
              }
            }
          }
        }
      }
    `,
  );
  if (!contactPage) {
    return (
      <SEO meta={[
        { 'http-equiv': 'refresh', content: '0;url=/' },
      ]}
      />
    );
  }
  const contactPageData = usePageRehydrate(contactPage);
  return (
    <GenericLayout>
      <ContainerContent>
        <SEO
          id={contactPageData.foreignId}
          title={contactPageData.metaTitleFormatted}
          description={contactPageData.metaTitleFormatted}
          ogImage={contactPageData.ogImage}
          metaTags={contactPageData.metaTags}
          slug={contactPageData.slug}
        />
        <RowHead>
          <Col display="flex" justifyContent="center">
            <Flex>
              <Box
                position="relative"
                top="9px"
                left="-25px"
                width="72px"
                ml="-72px"
                display={['none', 'block']}
              >
                <img src={imgBird} alt="" />
              </Box>
              <Box>
                <Title as="h1" size="h1" m="0 0 16px">
                  Contact us
                </Title>
                <Text letterSpacing="-0.5px" fontSize="xl">
                  Get in touch - we love hearing from you!
                </Text>
              </Box>
            </Flex>
          </Col>
        </RowHead>
        <Row gap={[null, 120, 164]}>
          <Col width={[1, 1 / 2]}>
            <ContactForm
              id="ContactForm"
              formData={contactPageData}
              formFields={contactPageData.formFields.edges}
              data-testid="contact__form"
            />

          </Col>
          <Col width={[1, 1 / 2]} line mt={[null, 20]} icon={imgFork}>
            <Title as="h3" size="h3" mb="0">
              First Table
            </Title>
            <Title color="gold.900" size="h6" as="div" m="0 0 31px">
              Limited
            </Title>
            {parseHtml(contactPageData.sidebarContent)}
          </Col>
        </Row>
      </ContainerContent>
    </GenericLayout>
  );
};

ContactUs.propTypes = {};
ContactUs.defaultProps = {};

export default ContactUs;
