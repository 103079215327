import React, { useEffect } from 'react';
import { Field, withFormik } from 'formik';
import { graphql as apolloGraphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import Box from '@firsttable/web-components/atoms/Box';
import Button from '@firsttable/web-components/atoms/Button';
import {
  errorMessage,
  formOpenEvent,
  scrollTo,
} from '@firsttable/functions';
import FormLayout from '@firsttable/web-components/organisms/Forms/Form';
import { withAlert } from 'react-alert';

const UPDATE_USER_FORM = gql`
  mutation updateUserForm($id: Int!, $data: String) {
    updateUserForm(id: $id, data: $data) {
      onCompleteMessage
    }
  }
`;

const DynamicUserForm = ({
  id,
  handleSubmit,
  isSubmitting,
  isModal,
  formFields,
  ...props
}) => {
  useEffect(
    () =>
      formOpenEvent({
        action: 'Dynamic Form - Open',
        label: id,
      }),
    [],
  );
  return (
    <FormLayout {...props} handleSubmit={handleSubmit} isModal={isModal}>
      {formFields.map(({ node }) => {
        if (node.type && node.type !== 'spamprotection') {
          return (
            <Field
              key={node.id}
              component={FormField}
              mb={['15px', '20px']}
              name={node.name}
              id={node.name}
              label={node.title}
              type={node.type}
              value={node.default}
              options={
                node.options
                  ? node.options.map((option) => JSON.parse(option))
                  : []
              }
            />
          );
        }
        return null;
      })}
      <Field
        component={FormField}
        type="recaptcha"
        name="recaptcha"
        id={`${id}_Recaptcha`}
      />
      <Box mb="20px">
        <Button size="l" wide kind="cta" type="submit" isLoading={isSubmitting}>
          Submit
        </Button>
      </Box>
    </FormLayout>
  );
};

DynamicUserForm.propTypes = {
  id: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isModal: PropTypes.bool,
  status: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  formFields: PropTypes.array.isRequired,
};
DynamicUserForm.defaultProps = {
  id: 'DynamicUserForm',
  isSubmitting: false,
  isModal: false,
  status: {},
};

export default compose(
  withAlert(),
  apolloGraphql(UPDATE_USER_FORM, { name: 'updateUserForm' }),
  withFormik({
    mapPropsToValues: (props) => {
      const initialValues = {
        recaptcha: '',
      };
      // eslint-disable-next-line array-callback-return
      props.formFields.map(({ node }) => {
        initialValues[node.name] = node.default || '';
      });
      return initialValues;
    },
    validationSchema: (props) => {
      const toValidate = {
        recaptcha: !window.Cypress
          ? Yup.string().required("Please confirm you're not a bot")
          : null,
      };
      props.formFields.map(({ node }) => {
        if (node.required) {
          if (node.type === 'EmailField') {
            toValidate[node.name] = Yup.string()
              .email('Invalid email')
              .required('This field is required');
          } else {
            toValidate[node.name] = Yup.string().required(
              'This field is required',
            );
          }
        }
        return true;
      });
      return Yup.object().shape({
        ...toValidate,
      });
    },
    handleSubmit: (
      values,
      {
        setSubmitting,
        setStatus,
        resetForm,
        props: { updateUserForm, formData, hideModal, alert },
      },
    ) => {
      updateUserForm({
        variables: { id: formData.foreignId, data: JSON.stringify(values) },
      })
        .then(({ error }) => {
          if (!error) {
            resetForm();
            const successMessage =
              formData.onCompleteMessage ||
              'Success! We will be in touch shortly';
            setStatus({ message: successMessage, type: 'success' });
            scrollTo('form-alert', -20);
            if (hideModal) {
              hideModal();
              alert.success(successMessage);
            }
          } else {
            setStatus({ message: errorMessage(error), type: 'danger' });
            if (hideModal) {
              alert.error(errorMessage(error));
            }
          }
          setSubmitting(false);
          return true;
        })
        .catch((e) => {
          setStatus({ message: errorMessage(e), type: 'danger' });
          if (hideModal) {
            alert.error(errorMessage(e));
          }
          setSubmitting(false);
        });
    },
  }),
)(DynamicUserForm);
