import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../components/organisms/Footer';
import MainMenu from '../components/organisms/Navigation/Menu';
import ThemeLayout from './layout';

const GenericLayout = ({
  children,
  menuTitle,
}) => (
  <ThemeLayout footer={<Footer />}>
    <MainMenu menuTitle={menuTitle} />
    {children}
  </ThemeLayout>
);

GenericLayout.propTypes = {
  children: PropTypes.any,
  menuTitle: PropTypes.string,
};
GenericLayout.defaultProps = {
  children: null,
};

export default GenericLayout;
